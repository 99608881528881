<template>
    <div>
        <!-- <div v-if="linkText == 'global_home_header_submenu_link_0_5'" class="list-item" /> -->
        <a
            class="global-route-link"
            :class="{ active: isActiveSubNav(), en: props.en, 'global-route-link-en': !isCN }"
            :href="props.routePath"
            @click="handleClick"
        >
            {{ $t(linkText) }}
            <CommonComponentsSVGCommonLittleRightArrow />
        </a>
        <div v-if="props.des" class="global-item-des" :class="{ 'global-item-des-en': !isCN }">
            {{ $t(props.des) }}
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useRoute } from 'vue-router';
    import { pushRouter } from '~~/composables/useRouterController';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isCN = ifCNLocale();
    const props = defineProps<{
        routePath: string;
        linkText: string;
        en: boolean;
        des?: string;
    }>();

    const route = useRoute();

    const isActiveSubNav = () => {
        const path = route.path || route.fullPath;
        return path === props.routePath;
    };

    const handleClick = (event) => {
        event.preventDefault(); // 阻止默认行为
        pushRouter(props.routePath);
        console.log('efewf', props.routePath);
    };
</script>

<style lang="less" scoped>
    .list-item {
        height: 1px;
        width: .convert(213px) [ @vw];
        background-color: rgba(255, 255, 255, 0.3);
        margin-bottom: .convert(20px) [ @vw];
        margin-top: .convert(-4px) [ @vw];
        @media (max-width: @screen-mobile-width) {
            width: 85vw;
            height: 1px;
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
    .global-route-link {
        color: #fff;
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: normal;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover,
        &.active {
            text-decoration: none !important;
            .svg-icon {
                opacity: 1;
            }
        }
        &.en {
            font-family: Montserrat;
            font-size: 20px;
            letter-spacing: 0px;
        }
        &.global-route-link-en {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
        }
        .svg-icon {
            // transition: opacity 0.5s ease-in;
            opacity: 0;
            font-size: 20px;
            /* 通过设置 font-size 来改变图标大小 */
            width: 1em;
            /* 图标和文字相邻时，垂直对齐 */
            vertical-align: -0.15em;
            /* 通过设置 color 来改变 SVG 的颜色/fill */
            fill: currentColor;
            /* path 和 stroke 溢出 viewBox 部分在 IE 下会显示
      normalize.css 中也包含这行 */
            overflow: hidden;
        }
    }

    .global-item-des {
        font-size: 14px;
        font-weight: 350;
        white-space: pre-wrap;
        opacity: 0.6;
        margin-top: 4px;
        font-family: HarmonyOS Sans SC;
    }
    .global-item-des-en {
        font-family: Montserrat;
        margin-right: 10px;
    }
</style>
