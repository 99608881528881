<script lang="ts" setup>
    import { ref, reactive, onMounted, onBeforeMount, computed } from 'vue';
    import consultImg from './consult.png';
    import HoverConsult from './HoverConsult.png';
    import consultorImg from './consultor.jpg';
    import codeImg from './code.png';
    import phoneImg from './phone.png';
    import backTop from './backTop.png';
    import HoverBackTop from './HoverBackTop.png';
    import { getIsPcCnRef, getIsMobileRef, getIsMobileCnRef } from '~~/composables/useMatchRules.ts';

    const isMobileCn = getIsMobileCnRef();
    const isMobile = getIsMobileRef();
    const isPcCn = getIsPcCnRef();
    const props = withDefaults(
        defineProps<{
            topVisible: boolean;
        }>(),
        {
            topVisible: false,
        },
    );

    const visible = ref(false);
    const codeVisible = ref(false);
    const hoverBackTopVisible = ref(false);

    const closeChat = () => {
        visible.value = false;
    };
    const openChat = () => {
        codeVisible.value = false;
        visible.value = true;
    };
    const openChatMobile = () => {
        codeVisible.value = false;
        window.open(
            'https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000049977&chatId=54ce9f29-a03a-4e4f-b9b3-6c0e2dea482a',
            '_blank',
        );
        // visible.value = true;
    };
    const closeCode = () => {
        codeVisible.value = false;
    };
    const openCode = () => {
        visible.value = false;
        codeVisible.value = true;
    };
    const backToTop = () => {
        hoverBackTopVisible.value = true;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
</script>
<template>
    <div :class="{ 'mobile-mask': isMobileCn && codeVisible }"></div>
    <div class="online-consult">
        <div :class="{ 'right-icon': !isMobileCn, 'mobile-right-icon': isMobile }">
            <!-- pc中文 -->
            <div class="back-top" @click="backToTop" v-show="props.topVisible" v-if="isPcCn">
                <div class="top-img default">
                    <img :src="backTop" alt="" />
                </div>
                <div class="top-img hover">
                    <img :src="HoverBackTop" alt="" />
                </div>
            </div>
            <!-- 移动 -->
            <div
                class="back-top"
                :class="{ 'back-top-en': !isMobileCn }"
                @click="backToTop"
                v-show="props.topVisible"
                v-else-if="isMobile"
            >
                <div class="top-img">
                    <img :src="HoverBackTop" alt="" />
                </div>
            </div>
            <!-- pc中文 -->
            <div class="right-bottom-consult" v-if="isPcCn">
                <div class="chat-top">
                    <a-popover
                        :visible="visible"
                        placement="leftBottom"
                        trigger="click"
                        :align="{ offset: [-10, 58] }"
                        overlayClassName="iframe-popover"
                    >
                        <template #content>
                            <iframe
                                src="https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000049977&chatId=54ce9f29-a03a-4e4f-b9b3-6c0e2dea482a"
                                frameborder="0"
                                width="400"
                                height="570"
                            ></iframe>
                            <div class="close-btn" @click="closeChat">×</div>
                        </template>
                        <!-- <a-button @click="openChat">咨询</a-button> -->
                        <div class="chat1">
                            <img class="top-img" :src="consultorImg" alt="" @click="openChat" />
                            <p class="top-text focus" v-if="visible" @click="openChat">在线咨询</p>
                            <div v-else>
                                <p class="top-text default" @click="openChat">在线咨询</p>
                                <p class="top-text active" @click="openChat">在线咨询</p>
                            </div>
                        </div>
                    </a-popover>
                </div>
                <div class="chat-bottom">
                    <a-popover
                        v-model:visible="codeVisible"
                        placement="leftBottom"
                        trigger="click"
                        :align="{ offset: [-15, 13] }"
                        overlayClassName="code-popover"
                    >
                        <template #content>
                            <div class="code">
                                <div class="code-top">
                                    <p class="code-text1">更多服务，欢迎咨询扫码</p>
                                    <p class="code-text2">获取功能/企业版购买/商务合作/建模学习等</p>
                                    <img :src="codeImg" alt="" />
                                </div>
                                <div class="code-bottom">
                                    <img :src="phoneImg" alt="" />
                                    <p>官方热线: 400-158-0699</p>
                                </div>
                            </div>
                        </template>
                        <div class="chat2">
                            <div class="bottom focus" @click="openCode" v-if="codeVisible">
                                <img class="bottom-img" :src="HoverConsult" alt="" />
                            </div>
                            <template v-else>
                                <div class="bottom default" @click="openCode">
                                    <img class="bottom-img" :src="consultImg" alt="" />
                                </div>
                                <div class="bottom active" @click="openCode">
                                    <img class="bottom-img" :src="HoverConsult" alt="" />
                                </div>
                            </template>
                        </div>
                    </a-popover>
                </div>
            </div>
            <!-- 移动中文 -->
            <div class="right-bottom-consult" v-else-if="isMobileCn">
                <div class="chat-top">
                    <div class="chat1">
                        <img class="top-img" :src="consultorImg" alt="" @click="openChatMobile" />
                        <p class="top-text focus" @click="openChatMobile">在线咨询</p>
                    </div>
                </div>
                <div class="chat-bottom">
                    <a-popover
                        v-model:visible="codeVisible"
                        placement="leftBottom"
                        trigger="click"
                        :align="{ offset: [-6, -88] }"
                        overlayClassName="code-popover"
                    >
                        <template #content>
                            <div class="code">
                                <div class="code-top">
                                    <p class="code-text1">更多服务，欢迎咨询扫码</p>
                                    <p class="code-text2">获取功能/企业版购买/商务合作/建模学习等</p>
                                    <img :src="codeImg" alt="" />
                                </div>
                                <a class="code-bottom" href="tel:+400-158-0699">
                                    <img :src="phoneImg" alt="" />
                                    <p>官方热线: <a href="tel:+400-158-0699">400-158-0699</a></p>
                                </a>
                            </div>
                        </template>
                        <div class="chat2">
                            <div class="bottom" @click="openCode">
                                <img class="bottom-img" :src="HoverConsult" alt="" />
                            </div>
                        </div>
                    </a-popover>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .online-consult {
        position: relative;
    }
    .mobile-mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: #000000;
        opacity: 0.6;
        z-index: 999;
    }
    .mobile-right-icon {
        bottom: 108px;
        position: fixed;
        z-index: 100;
        width: 44px;
        height: 172px;
        right: 10px;
        // bottom: 10%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .right-icon {
        position: fixed;
        z-index: 100;
        width: 44px;
        height: 172px;
        right: 10px;
        bottom: 10%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .back-top-en {
        margin-bottom: 110px !important;
    }
    .back-top {
        width: 44px;
        height: 44px;
        border-radius: 90px;
        background: #0000004d;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 16px;
        border: 1px solid rgba(256, 256, 256, 0.2);
        backdrop-filter: blur(8px);
        img {
            width: 20px;
        }
        .default {
            display: flex;
        }
        .hover {
            display: none;
        }
        &:hover {
            .default {
                display: none;
            }
            .hover {
                display: flex;
            }
        }
    }
    .right-bottom-consult {
        width: 44px;
        height: 112px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #0000004d;
        border-radius: 90px;
        font-size: 10px;
        color: #ffffff;
        cursor: pointer;
        border: 1px solid rgba(256, 256, 256, 0.2);
        backdrop-filter: blur(8px);

        .chat1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: -3px;
            p {
                opacity: 40%;
                margin-top: 4px;
            }
            .default {
                display: flex;
            }
            .active {
                display: none;
            }
            .focus {
                opacity: 100%;
            }

            &:hover {
                .default {
                    display: none;
                }
                .active {
                    display: flex;
                    opacity: 100%;
                }
            }
            .top-img {
                width: 32px;
                border-radius: 90px;
            }
        }
        .chat2 {
            margin-top: 8px;
            .bottom {
                width: 32px;
                height: 32px;
                border-radius: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                .bottom-img {
                    width: 20px;
                }
            }

            .default {
                display: flex;
            }
            .active {
                display: none;
            }
            .focus {
                background: #0000004d;
            }

            &:hover {
                .default {
                    display: none;
                }
                .active {
                    display: flex;
                    background: #0000004d;
                }
            }
        }
    }
    .code {
        width: 268px;
        height: 318px;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 16px;
        font: HarmonyOS Sans SC;
        .code-top {
            width: 248px;
            height: 203px;
            background: #f5f5f5;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            align-items: center;
            .code-text1 {
                font-size: 14px;
                margin-top: 20px;
            }
            .code-text2 {
                color: #999999;
                font-size: 12px;
                margin-top: 8px;
            }
            img {
                width: 88px;
                margin-top: 24px;
            }
        }
        .code-bottom {
            width: 248px;
            height: 84px;
            background: #f5f5f5;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 10px;
            text-decoration: none;
            img {
                width: 16px;
                margin-top: 6px;
                // margin-bottom: 16px;
            }
            p {
                color: #000000;
            }
            a {
                color: #000000;
                text-decoration: underline;
            }
        }
    }
</style>
<style lang="less">
    .ant-popover {
        padding-right: 0px;
        position: fixed;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background-color: transparent;
        border-radius: 16px;

        .ant-popover-inner-content {
            padding: 0;

            .close-btn {
                position: absolute;
                top: 21px;
                right: 20px;
                color: #fff;
                width: 18px;
                height: 16px;
                font-size: 30px;
                line-height: 13px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
    .iframe-popover {
        .ant-popover-inner {
            height: 570px;
        }
    }
    .code-popover {
        width: 268px;
        height: 318px;
    }
</style>
