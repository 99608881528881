<script lang="ts" setup>
    import roleTop from './roleTop.png';
    import roleBottom from './roleBottom.png';
    import backImg from './back.png';
    import arrowImg from './RightArrow.png';
    import studioImg from './Studio.png';
    import atelierImg from './Atelier.png';
    import simImg from './Simulator.png';
    import { useRouter } from 'vue-router';
    import { ref, reactive } from 'vue';

    const isPersonVisible = ref(false);
    const isRoleVisible = ref(true);
    const router = useRouter();
    const prosuctsList = reactive([
        {
            logo: studioImg,
            name: 'Style3D Studio',
            path: 'https://studio.style3d.com/en',
        },
        {
            logo: atelierImg,
            name: 'Style3D Atelier',
            path: 'https://atelier.style3d.com/',
        },
        {
            logo: simImg,
            name: 'Style3D Simulator',
            path: 'https://simulator.style3d.com/',
        },
    ]);
    const toApply = () => {
        router.push({ path: '/apply' });
        window.gtag('event', 'click_apply_entry', { page: location.href });
    };
    const toPerson = () => {
        isRoleVisible.value = false;
        isPersonVisible.value = true;
    };
    const back = () => {
        isRoleVisible.value = true;
        isPersonVisible.value = false;
    };
    const toProduct = (path) => {
        window.open(path, '_blank');
    };
</script>
<template>
    <!-- 角色选择 -->
    <div class="role-container" v-if="isRoleVisible">
        <div class="role-content">
            <div class="role person" @click="toPerson">
                <div class="bg"><img :src="roleTop" /></div>
                <div class="text">Individual User</div>
            </div>
            <div class="role" @click="toApply">
                <div class="bg"><img :src="roleBottom" /></div>
                <div class="text">Enterprise User</div>
            </div>
        </div>
    </div>
    <!-- 个人用户 -->
    <div class="person-container" v-if="isPersonVisible">
        <div class="nav" @click="back">
            <img :src="backImg" />
            <div class="title">Individual Products</div>
        </div>
        <div class="products">
            <div v-for="(item, itemIndex) in prosuctsList" class="products-list" @click="toProduct(item.path)">
                <img :src="item.logo" class="logo" />
                <div class="name">{{ item.name }}</div>
                <img :src="arrowImg" class="arrow" />
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .person-container {
        border-radius: 8px;
        background: #fff;
        // width: 244px;
        // height: 172px;
        padding: 20px 0px 28px 0px;
        .nav {
            display: flex;
            margin-left: 24px;
            cursor: pointer;
            img {
                width: 16px;
            }
            .title {
                margin-left: 8px;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.63px;
                text-align: left;
                opacity: 0.8;
            }
        }
        .products {
            margin-top: 20px;
            .products-list {
                display: flex;
                align-items: center;
                width: 244px;
                height: 44px;
                cursor: pointer;
                position: relative;
                &:hover {
                    background: #f4f5fa;
                }
                .logo {
                    margin-left: 24px;
                    width: 24px;
                    height: 24px;
                }
                .name {
                    margin-left: 8px;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17.07px;
                    text-align: left;
                }
                .arrow {
                    position: absolute;
                    right: 20px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .role-container {
        width: 244px;
        height: 172px;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12px 15px;
        .role {
            width: 214px;
            height: 68px;
            background: #f4f5fa;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .bg {
                width: 32px;
                height: 32px;
                background: #fff;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                img {
                    width: 20px;
                }
            }
            .text {
                font-size: 14px;
                font-weight: 500;
                margin-left: 0;
            }
        }
        .person {
            margin-bottom: 12px;
        }
    }
</style>
